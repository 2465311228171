<template>
  <v-container fluid class="pt-0">

    <div class="text-h5">图书列表</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          dense
          label="输入书名查询"
          solo
          @input="changeSearch"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="3">
        <v-btn color="primary lighten-3" class="mr-5" @click="addItem">新增</v-btn>
        <v-btn color="primary" @click="query">查询</v-btn>
      </v-col>
      <v-col cols="6" md="3">
        <v-select dense outlined solo :items="cates" v-model="cate" label="分类查询" return-object @change="changeCate">
        </v-select>
      </v-col>
    </v-row>
    <v-card flat min-height="300" tile>
      <v-simple-table v-if="refresh">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">书名</th>
              <th class="text-left">封面</th>
              <th class="text-left">分类</th>
              <th class="text-left">编号</th>
              <th class="text-left">作者</th>
              <th class="text-left">出版日期</th>
              <th class="text-left">价格</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>

            <tr v-if="table_data.length <= 0">
              <td colspan="4" style="text-align: center">
                暂无数据
              </td>
            </tr>

            <tr
              v-for="(item, index) in table_data"
              :key="item.id"
              :style="index % 2 === 0 ? 'background: rgb(242,242,242)' : ''"
            >
              <td>{{ item.title }}</td>
              <td>
                <v-img :src="item.dimensional+randomPath" :aspect-ratio="1.4/2" width="80" class="my-2"></v-img>
              </td>
              <td>{{ getType(item.categories) }}</td>
              <td> {{item.book_number}}</td>
              <td> {{item.author}} </td>
              <td>{{item.datetime}}</td>
              <td> {{item.price}} </td>
<!--              <td>{{item.stocks}}</td>-->
              <td>
                <v-btn color="primary" class="mr-1" small @click="editItem(item.id)"
                  >编辑</v-btn
                >
                <v-btn color="primary" class="mr-1" small @click="cate_dialog=true;id=item.id;category=item.categories">
                  修改分类
                </v-btn>
                <v-btn v-show="item.data_status" color="error" small @click="deleteItem(item.id)"
                >下架</v-btn
                >
                <!-- <v-btn style="border: 1px solid #1976D2;margin-left: 3px" text  small color="primary">删除</v-btn>-->
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <div v-if="refresh" class="pagination" style="width: 500px">
          <v-pagination
              v-if="table_data.length > 0"
              v-model="search.page"
              :length="pageCount"
              @input="(e) => doGet(e)"
          ></v-pagination>
    </div>
    <v-dialog v-model="dialog" width="800px">
      <v-card>
        <v-card-title>下架图书</v-card-title>
        <v-card-text>
          请问是否要下架这本图书？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog=false">取消</v-btn>
          <v-btn @click="takeDown">确认</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cate_dialog" max-width="800">
      <v-card>
        <v-card-title>
          选择分类
        </v-card-title>
        <v-card-text>
          <v-select
              v-model="category"
              :items="categories"
              :menu-props="{ maxHeight: '400' }"
              item-text="name"
              item-value="id"
              label="Select"
              multiple
              hint="请选择需要的图书分类"
              persistent-hint
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small text @click="changCate">确认修改</v-btn>
          <v-btn small text @click="cate_dialog=false;category=[]">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { getBookList ,getCosKey,deleteBook,getBookCategories,updateCate} from "@/api/books";
import _ from 'lodash';
export default {
  name: "projectManage",
  created() {

  },
  mounted() {
    this.doGet();
    getBookCategories()
        .then(result => {
          console.log(' categories = ', result.results)
          this.categories = result.results
          this.cateForm=result.results
          this.cateForm=this.cateForm.reduce((acc,cur)=>{
            acc[cur.id]=cur.name
            return acc
          },{})
        })
        .catch(error => {
        })
  },
  methods: {
    doGet(page = this.search.page) {
      getBookList({ ...this.search, page }).then((res) => {
        this.table_data = res.results;
        this.total = res.count;
        this.refresh = false;
        this.$nextTick(() => {
          this.refresh = true;
        });
      });
    },

    changeCate(){
      const {value}=this.cate
      if (value===''){
        this.search.status=1
        this.$delete(this.search,'categories')
      }
      if (value===0){
        this.search.status=value
        this.$delete(this.search,'categories')
      }
      if (value) {
        this.search.status=1
        this.search.categories=this.cate.value
      }
      this.search.page=1
      this.doGet()
    },

    changeSearch: _.debounce(function (e) {
      this.search.search=e
      this.search.page=1
      this.doGet()
    }, 500),

    editItem(id) {
      this.$router.push({ name: "edit_book", params: { id } });
    },
    async deleteItem(id){
      this.id=id
      this.dialog=true
    },
    async takeDown(){
      await deleteBook(this.id)
      this.$snackbar({
        content: '已下架',
        color: 'success',
        showClose: true,
      })
      this.dialog=false
      this.doGet(this.search.page)
    },
    addItem(){
        this.$router.push({ name: "add_book"});
    },
    query(){
      this.search.page=1
      this.doGet()
    },
    async changCate(){
      try {
        await updateCate({categories:this.category},this.id)
        this.$snackbar({
          content: '修改分类成功',
          color: 'success',
          showClose: true,
        })
        this.doGet()
      }catch (e) {
        this.$snackbar({
          content: '修改分类失败',
          color: 'error',
          showClose: true,
        })
      }finally {
        this.cate_dialog=false
        this.category=[]
      }
    }
  },
  data: () => ({
    dialog:false,
    refresh: true,
    search: {
      page: 1,
      pagesize: 10,
      search:'',
      status:1,
    },
    total: 0,
    table_data: [],
    randomPath:'?t='+Math.random(),
    id:'',
    cates:[
      {
        text:'所有分类',
        value:''
      },
      {
        text:'童趣时光',
        value: 1
      },
      {
        text:'孕产育儿',
        value: 2
      },
      {
        text:'养生保健',
        value: 3
      },
      {
        text:'花鸟园艺',
        value: 4
      },
      {
        text:'益智游戏',
        value: 5
      },
      {
        text:'已下架',
        value: 0
      }
    ],
    cate:{
      value:''
    },

    cateForm:[],
    categories:[],
    category:[],
    cate_dialog:false
  }),
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.search.pagesize);
    },
    getType(){
      return function (val) {
        return val.map(item=>this.cateForm[item]).toString()
      }
    }
  },
};
</script>
<style lang="stylus" scoped>
.n-title
	font-size 32px

.checkbox >>> .v-label
	font-size 12px
	color #212529
	margin-left -10px !important
	margin-right 10px !important

.pagination
	float: right
</style>
